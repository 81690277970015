import React, { FC, useState, useEffect, Fragment } from 'react';
import { IStatesAndCity } from '../models/StatesAndCity';
import { ICompareData } from '../models/CompareData';
import { Env } from '../AppSettings';
import { SearchedText } from './SearchedText';
import { keyUpWrapper } from '../helper/common';

interface IProps {
  locationCities: IStatesAndCity[] | null;
  compareData: ICompareData[] | null;
  cityCodes: string[];
  catCode: string;
  catName: string;
  searchTextCity: string | null;
  setEditCityCode: (fieldName: string) => void;
  setOpenCityModal: (fieldName: boolean) => void;
  setOpenConfirmModal: (fieldName: boolean) => void;
  setRemoveCityCode: (fieldName: string) => void;
  setRemoveCityName: (fieldName: string) => void;
  openConfirmModalClosed: boolean;
  setOpenConfirmModalClosed: (fieldName: boolean) => void;
}

export const CompareTableCity: FC<IProps> = ({
  locationCities,
  compareData,
  cityCodes,
  catCode,
  catName,
  searchTextCity,
  setEditCityCode,
  setOpenCityModal,
  setOpenConfirmModal,
  setRemoveCityCode,
  setRemoveCityName,
  openConfirmModalClosed,
  setOpenConfirmModalClosed,
}) => {
  //////////////////////////////////// USESTATE ///////////////////////////////////////
  const [catCompareData, setCatCompareData] = useState<ICompareData[]>([]);

  //////////////////////////////////// EFFECTS ///////////////////////////////////////
  //1. Get compare data for this category
  useEffect(() => {
    setCatCompareData(thisCompareData());
    function thisCompareData(): ICompareData[] {
      let compareDataForThisInd: ICompareData[] = [];
      if (compareData) {
        compareDataForThisInd = compareData.filter((row) => row.catCode === catCode);
      }
      return compareDataForThisInd;
    }
  }, [catCode, compareData]);

  //////////////////////////////////////// CONSTANT //////////////////////////////////////////////
  const locationNames = () => {
    let locNames = 'United States';
    if (cityCodes) {
      cityCodes.forEach((cityCode) => {
        let city = locationCities?.find((loc) => loc.code === cityCode);
        if (city) {
          locNames = locNames + '; ' + city.label + ', ' + city.parentId2;
        }
      });
    }
    return locNames;
  };

  const IndicatorIds = () => {
    let uniqueIndicatorIds: string[];
    uniqueIndicatorIds = [...new Set(catCompareData.map((item) => item.indicatorId))]; // [ 'A', 'B']
    return uniqueIndicatorIds;
  };

  //////////////////////////////////// FUNCTIONS ///////////////////////////////////////
  function getCityName(cityCode: string) {
    let cityName = '';
    if (cityCode) {
      let city = locationCities?.find((location) => location.code === cityCode);
      if (city) {
        cityName = city.label + ', ' + city.stratificationAbbr;
      }
    }
    return cityName;
  }
  function getHeaderText(cityCode: string) {
    let headerText = '';
    if (cityCode) {
      let city = compareData?.find((row) => row.cityId?.toString() === cityCode);
      if (city) {
        headerText = city.columnHeaderText;
      }
    } else {
      let country = compareData?.find((row) => row.cityId === null);
      if (country) {
        headerText = country.columnHeaderText;
      }
    }
    return headerText;
  }
  function getIndicatorData(indicatorId: string): ICompareData[] {
    return catCompareData.filter((row) => row.indicatorId === indicatorId);
  }
  function getDataRow(indData: ICompareData[], cityId: string): ICompareData | null {
    var result: ICompareData[];
    if (cityId === '') {
      //result = indData?.filter((row) => row.location === 'United States' && row.indicator.trim() !== '');
      result = indData?.filter((row) => row.location === 'United States' && row.typeUnitCI.includes('Crude'));
    } else {
      //result = indData?.filter((row) => row.cityId?.toString() === cityId && row.indicator.trim() !== '');
      result = indData?.filter((row) => row.cityId?.toString() === cityId && row.typeUnitCI.includes('Crude'));
    }
    if (result && result.length > 0) {
      return result[0];
    } else {
      return null;
    }
  }
  function getDataRowAdj(indData: ICompareData[], cityId: string): ICompareData | null {
    //debugger;
    var result: ICompareData[];
    if (cityId === '') {
      //result = indData?.filter((row) => row.location === 'United States' && row.indicator.trim() === '');
      result = indData?.filter((row) => row.location === 'United States' && row.typeUnitCI.includes('Age-adjusted'));
    } else {
      //result = indData?.filter((row) => row.cityId?.toString() === cityId && row.indicator.trim() === '');
      result = indData?.filter((row) => row.cityId?.toString() === cityId && row.typeUnitCI.includes('Age-adjusted'));
    }
    if (result && result.length > 0) {
      return result[0];
    } else {
      return null;
    }
  }
  function getDefinitionLink(definitionPage: string | null, indicatorAnchor: string | null) {
    let pageUrl = Env('ind_definition_url');
    if (definitionPage) {
      let arPage = definitionPage.split('#');
      let CatName = arPage[0]; //Health-outcomes
      let Ind: string = indicatorAnchor == null ? '' : indicatorAnchor; //arPage.length > 1 ? arPage[1] : ''; //#arthritis
      pageUrl = pageUrl.replace('[CategoryName]', CatName).replace('[indicatorId]', Ind);
    }
    return pageUrl;
  }
  //////////////////////////////////// HANDLE EVENTS ///////////////////////////////////////
  function removeCityClicked(e: React.MouseEvent<HTMLElement>, cityCodeSelected: string) {
    //e.preventDefault();
    if (cityCodeSelected) {
      if (openConfirmModalClosed) {
        setOpenConfirmModalClosed(false);
      } else {
        setRemoveCityCode(cityCodeSelected);
        setRemoveCityName(getCityName(cityCodeSelected));
        setOpenConfirmModal(true);
      }
    }
  }

  function editCityClicked(e: React.MouseEvent<HTMLElement>, cityCodeSelected: string) {
    //e.preventDefault();
    if (cityCodeSelected) {
      setEditCityCode(cityCodeSelected);
      setOpenCityModal(true);
    }
  }
  //////////////////////////////////// FUNCTION ///////////////////////////////////////////
  function getClassName(i: number): string {
    if (i % 2 === 1) {
      return 'bg-light';
    } else {
      return '';
    }
  }
  //////////////////////////////////// RETURN /////////////////////////////////////////////
  return (
    <div>
      <table id={'table' + catCode} className="table topic-table category-details table-bordered table-hover">
        <caption className="d-none">
          This table contains data for all indicators in the topic {catName} and contains column headers for the
          Measure, the Data Type, and for the selected location(s): {locationNames()}.
        </caption>
        <thead>
          <tr>
            <th scope="col" className="align-middle">
              <SearchedText text="Measure" searchTerm={searchTextCity} />
            </th>
            <th scope="col" className="text-center align-middle">
              <SearchedText text="Data Type" searchTerm={searchTextCity} />
            </th>
            <th scope="col" className="text-center align-bottom font-normal">
              {/* {ReactHtmlParser(getHeaderText(''))} */}
              <SearchedText text={getHeaderText('')} searchTerm={searchTextCity} />
              {cityCodes && cityCodes.length > 0 && (
                <Fragment>
                  <br />
                  <br />
                </Fragment>
              )}
            </th>
            {cityCodes?.map((cityCode) => {
              return (
                <th scope="col" key={cityCode} className="text-center align-bottom font-normal">
                  {/* {ReactHtmlParser(getHeaderText(cityCode))} */}
                  <SearchedText text={getHeaderText(cityCode)} searchTerm={searchTextCity} />
                  <div className="edit-remove-link">
                    <span
                      className="link"
                      role="button"
                      tabIndex={0}
                      onClick={(e) => editCityClicked(e, cityCode)}
                      onKeyUp={(e) => keyUpWrapper(e, editCityClicked, cityCode)}
                    >
                      Edit
                    </span>
                    <span> | </span>
                    <span
                      className="link"
                      role="button"
                      tabIndex={0}
                      onClick={(e) => removeCityClicked(e, cityCode)}
                      onKeyUp={(e) => keyUpWrapper(e, removeCityClicked, cityCode)}
                    >
                      Remove
                    </span>
                  </div>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {IndicatorIds().map((indicatorId, i) => {
            //row
            let indData = getIndicatorData(indicatorId);
            let usRow = getDataRow(indData, '');
            let usRowAdj = getDataRowAdj(indData, '');
            return (
              <Fragment key={indicatorId}>
                <tr key={indicatorId} className={getClassName(i)}>
                  <th scope="row">
                    {/* {usRow?.indicator} */}
                    <SearchedText text={usRow?.indicator + ' - ' + usRow?.year} searchTerm={searchTextCity} />
                    <div className="view-definition">
                      <a
                        href={getDefinitionLink(usRow && usRow.definitionPage, usRow && usRow.indicatorAnchor)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        view definition
                      </a>
                    </div>
                  </th>
                  <td className="text-center">
                    {/* {usRow && ReactHtmlParser(usRow.typeUnitCI)} */}
                    <span className="text-no-break">
                      <SearchedText text={usRow?.typeUnitCI} searchTerm={searchTextCity} />
                    </span>
                  </td>
                  <td className="text-center">
                    {/* {usRow && ReactHtmlParser(usRow.valueCI)} */}
                    <SearchedText text={usRow?.valueCI} searchTerm={searchTextCity} />
                  </td>
                  {cityCodes?.map((cityCode) => {
                    let cityRow = getDataRow(indData, cityCode);
                    return (
                      <td className="text-center">
                        <SearchedText text={cityRow?.valueCI} searchTerm={searchTextCity} />
                      </td>
                    );
                  })}
                </tr>
                <tr key={indicatorId + 'adj'} className={getClassName(i)}>
                  <th scope="row">
                    <span className="row-indicator visually-hidden">usRow?.indicator + ' - ' + usRow?.year</span>
                    {/* {usRowAdj?.indicator} */}
                    {/* <SearchedText text={usRowAdj?.indicator} searchTerm={searchTextCity} /> */}
                  </th>
                  <td className="text-center">
                    {/* {usRowAdj && ReactHtmlParser(usRowAdj.typeUnitCI)} */}
                    <span className="text-no-break">
                      <SearchedText text={usRowAdj?.typeUnitCI} searchTerm={searchTextCity} />
                    </span>
                  </td>
                  <td className="text-center">
                    {/* {usRowAdj && ReactHtmlParser(usRowAdj.valueCI)} */}
                    <SearchedText text={usRowAdj?.valueCI} searchTerm={searchTextCity} />
                  </td>
                  {cityCodes?.map((cityCode) => {
                    let cityRowAdj = getDataRowAdj(indData, cityCode);
                    return (
                      <td className="text-center">
                        {/* {cityRowAdj && ReactHtmlParser(cityRowAdj.valueCI)} */}
                        <SearchedText text={cityRowAdj?.valueCI} searchTerm={searchTextCity} />
                      </td>
                    );
                  })}
                </tr>
              </Fragment>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
