import React, { FC, useState, useEffect } from 'react';
import { keyUpWrapper } from '../helper/common';
import { ICategory } from '../models/Category';
import { IStatesAndCity } from '../models/StatesAndCity';
import { ICompareData } from '../models/CompareData';
import { GetCategories } from '../api/ComparisonData';
import { GetCompareData } from '../api/ComparisonData';
import { CompareTableCity } from './CompareTableCity';
import { SearchedText } from './SearchedText';
import { ConfirmModal } from './ConfirmModal';
import { ConstantClass } from '../helper/ConstantClass';
import { useSetTitle } from '../helper/doAnalytics';

interface IProps {
  locationCities: IStatesAndCity[] | null;
  cityCodes: string[];
  searchTextCity: string | null;
  setCityCodes: (fieldName: string[]) => void;
  setEditCityCode: (fieldName: string) => void;
  setOpenCityModal: (fieldName: boolean) => void;
  setSearchCountCity: (fieldName: number) => void;
  firstLoad: boolean;
}

export const CompareCategoryCity: FC<IProps> = ({
  locationCities,
  cityCodes,
  searchTextCity,
  setCityCodes,
  setEditCityCode,
  setOpenCityModal,
  setSearchCountCity,
  firstLoad,
}) => {
  const [categories, setCategories] = useState<ICategory[]>([]);
  const [compareDataAPI, setCompareDataAPI] = useState<ICompareData[] | null>(null);
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [openConfirmModalClosed, setOpenConfirmModalClosed] = useState<boolean>(false);
  const [removeCityCode, setRemoveCityCode] = useState<string>('');
  const [removeCityName, setRemoveCityName] = useState<string>('');

  //////////////////////////////////////// ANALYTICS ///////////////////////////////////////
  useSetTitle('City Compare Measure');

  //////////////////////////////////////// EFFECTS /////////////////////////////////////////////
  //1. Get categories
  useEffect(() => {
    const getCategoriesAPI = async () => {
      return await GetCategories(ConstantClass.City, '');
    };
    getCategoriesAPI().then((res) => {
      if (res) {
        setCategories(res);
      }
    });
  }, []);

  //2.Get CompareData
  useEffect(() => {
    if (!firstLoad) {
      const getCompareDataAPI = async () => {
        return await GetCompareData(ConstantClass.City, getLocationCodes());
      };
      getCompareDataAPI().then((res) => {
        if (res) {
          //console.log('GetCompareData');
          //console.log(res);
          setCompareDataAPI(res);
        }
      });
    }
    //function
    function getLocationCodes() {
      let locCodes = '';
      if (cityCodes && cityCodes.length > 0) {
        locCodes = cityCodes.join(',');
      }
      return locCodes;
    }
  }, [firstLoad, cityCodes]);

  //3. Search
  useEffect(() => {
    if (searchTextCity) {
      setSearchCountCity(countMatches());
      //setOpenedTopics(createTopicsMap({ term: searchTerm }));
    }
    /////////////////////////
    function countMatches() {
      let count = 0;
      let match = null;

      if (searchTextCity) {
        const pattern = new RegExp(searchTextCity, 'gi');

        //1. categories
        categories.forEach((category) => {
          match = category.category.match(pattern);
          if (match) count += match.length;
        });
        //2. HeaderText
        if (searchTextCity.toLowerCase() === 'measure') {
          count += categories.length;
        }
        if (searchTextCity.toLowerCase() === 'data type') {
          count += categories.length;
        }
        match = getHeaderText('').match(pattern); //united states
        if (match) count += match.length * categories.length;
        if (cityCodes) {
          cityCodes.forEach((cityCode) => {
            match = getHeaderText(cityCode).match(pattern);
            if (match) count += match.length * categories.length;
          });
        }
        //3. compareDataAPI
        compareDataAPI?.forEach((data) => {
          //Indicator without year
          let match = data.indicator.match(pattern);
          if (match) count += match.length / 2;
          // /2 becuase there is adjacent row with the same name but hidden
          else {
            //Indicator with year
            match = (data.indicator + ' - ' + data.year).match(pattern);
            if (match) count += match.length / 2; // /2 becuase there is adjacent row with the same name but hidden
          }
          //DataType
          match = data.typeUnitCI.match(pattern);
          if (match) count += match.length;
          //HeaderText Location
          match = data.valueCI.match(pattern);
          if (match) count += match.length;
        });
        //
      }
      return count;
    }
    function getHeaderText(cityCode: string) {
      let headerText = '';
      if (cityCode) {
        let city = compareDataAPI?.find((row) => row.cityId?.toString() === cityCode);
        if (city) {
          headerText = city.columnHeaderText;
        }
      } else {
        let country = compareDataAPI?.find((row) => row.cityId === null);
        if (country) {
          headerText = country.columnHeaderText;
        }
      }
      return headerText;
    }
  }, [categories, compareDataAPI, cityCodes, searchTextCity, setSearchCountCity]);

  ////////////////////////////////////// FUNCTIONS ////////////////////////////////////////////
  function confirmModalReturn(answer: string) {
    if (answer === 'remove') {
      if (removeCityCode) {
        let thisCityCodes = cityCodes.filter((el) => el !== removeCityCode);
        setCityCodes(thisCityCodes);
      }
    } else {
      setOpenConfirmModalClosed(true);
    }
  }
  //////////////////////////////////// HANDLE EVENTS ///////////////////////////////////////
  function CategoryClicked(e: React.MouseEvent<HTMLElement>, catCode: string) {
    //e.preventDefault();
    let pmIcon = document.getElementById(catCode);
    let tableDiv = document.getElementById(catCode + 'wrapper');
    if (pmIcon && tableDiv) {
      if (tableDiv.style.display === 'none') {
        tableDiv.style.display = 'block';
        pmIcon.className = 'fi cdc-icon-minus topic-collapse__plusminus';
        pmIcon.style.transform = 'rotate(-180deg)';
      } else {
        tableDiv.style.display = 'none';
        pmIcon.className = 'fi cdc-icon-plus topic-collapse__plusminus';
        pmIcon.style.transform = 'rotate(0deg)';
      }
    }
  }

  function SetOpenAll(e: React.MouseEvent<HTMLElement>, openAllCat: boolean) {
    categories?.forEach(({ catCode }) => {
      let pmIcon = document.getElementById(catCode);
      let tableDiv = document.getElementById(catCode + 'wrapper');
      if (pmIcon && tableDiv) {
        if (openAllCat) {
          tableDiv.style.display = 'block';
          pmIcon.className = 'fi cdc-icon-minus topic-collapse__plusminus';
          pmIcon.style.transform = 'rotate(-180deg)';
        } else {
          tableDiv.style.display = 'none';
          pmIcon.className = 'fi cdc-icon-plus topic-collapse__plusminus';
          pmIcon.style.transform = 'rotate(0deg)';
        }
      }
    });
  }
  //////////////////////////////////////// RETURN ///////////////////////////////////////
  return (
    <>
      <div className="gray-border">
        <div className="row m-0 pt-2 pb-2 cat-header">
          <div className="col-2">
            <strong>Category</strong>
          </div>
          <div className="col-10 expand-collase">
            <div>
              <span
                className="category-link px-1"
                role="button"
                tabIndex={0}
                onClick={(e) => SetOpenAll(e, true)}
                onKeyUp={(e) => keyUpWrapper(e, SetOpenAll, true)}
              >
                Expand All
              </span>
              /
              <span
                className="category-link px-1"
                role="button"
                tabIndex={0}
                onClick={(e) => SetOpenAll(e, false)}
                onKeyUp={(e) => keyUpWrapper(e, SetOpenAll, false)}
              >
                Collapse All
              </span>
            </div>
          </div>
        </div>
        <div className="mt-1">
          {categories?.map((category) => (
            <div key={category.catCode} className="topic-collapse gray-border pt-2 ps-2" tabIndex={0}>
              <div>
                <span
                  id={category.catCode}
                  role="button"
                  tabIndex={0}
                  className="fi cdc-icon-minus topic-collapse__plusminus"
                  aria-label="collapse"
                  onClick={(e) => CategoryClicked(e, category.catCode)}
                  onKeyUp={(e) => keyUpWrapper(e, CategoryClicked, category.catCode)}
                ></span>
                <label
                  htmlFor={category.catCode}
                  className="link px-1 ms-1 pb-0"
                  role="button"
                  tabIndex={0}
                  onClick={(e) => CategoryClicked(e, category.catCode)}
                  onKeyUp={(e) => keyUpWrapper(e, CategoryClicked, category.catCode)}
                >
                  {/* <strong>{category.category}</strong> */}
                  <strong>
                    <SearchedText text={category.category} searchTerm={searchTextCity} />
                  </strong>
                </label>
              </div>
              <div id={category.catCode + 'wrapper'} className="ms-4 mb-2">
                <CompareTableCity
                  locationCities={locationCities}
                  compareData={compareDataAPI}
                  cityCodes={cityCodes}
                  catCode={category.catCode}
                  catName={category.category}
                  searchTextCity={searchTextCity}
                  setEditCityCode={setEditCityCode}
                  setOpenCityModal={setOpenCityModal}
                  setOpenConfirmModal={setOpenConfirmModal}
                  setRemoveCityCode={setRemoveCityCode}
                  setRemoveCityName={setRemoveCityName}
                  openConfirmModalClosed={openConfirmModalClosed}
                  setOpenConfirmModalClosed={setOpenConfirmModalClosed}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* /////////////////////////////// MODAL ///////////////////////////////////////// */}
      <ConfirmModal
        openConfirmModal={openConfirmModal}
        setOpenConfirmModal={setOpenConfirmModal}
        removeLocationName={removeCityName}
        confirmModalReturn={confirmModalReturn}
      />
    </>
  );
};
