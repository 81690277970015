import { doInteraction, doAnalytics, trigger_interaction } from './doAnalytics';

export function pageLoad() {
  doAnalytics({
    levels: ['Chronic', 'DPH', 'DPH Apps'],
    AzEntry: '',
    channel: 'DPH PLACES DTM Open Data',
  });
}

// User clicks Save on Add/Remove modal
export function changeLocation(view: string, locationIds: string[]) {
  //doInteraction(`DPH PLACES DTM-CRL ${view} ${locationIds.join('-')}`);
  trigger_interaction(`Add/Remove ${view}`, 'o_link', `DPH PLACES DTM-CRL ${view} ${locationIds.join('-')}`);
}

// User clicks Save as CSV
export function saveAsCSV(view: string, locationIds: string[]) {
  //doInteraction(`DPH PLACES DTM-CRE ${view} ${locationIds.join('-')},CSV`);
  trigger_interaction('Save As CSV', 'o_link', `DPH PLACES DTM-CRE ${view} ${locationIds.join('-')}, CSV`);
}

// User clicks Save as CSV
export function saveAsPDF(view: string, locationIds: string[]) {
  //doInteraction(`DPH PLACES DTM-CRE ${view} ${locationIds.join('-')},PDF`);
  trigger_interaction('Save As PDF', 'o_link', `DPH PLACES DTM-CRE ${view} ${locationIds.join('-')}, PDF`);
}
