import React, { FC, useState, useEffect } from 'react';
import * as analytics from '../helper/analytics';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import { ICompareDataCSV } from '../models/CompareDataCSV';
import { ExportCompareDataCSV } from '../api/ComparisonData';
import { keyUpWrapper } from '../helper/common';
import { ConstantClass } from '../helper/ConstantClass';
import Moment from 'moment';

interface IProps {
  viewType: string;
  locationCodes: string[];
}

export const SaveAsCSV: FC<IProps> = ({ viewType, locationCodes }) => {
  //////////////////////////////////// USESTATE ///////////////////////////////////////
  const [compareDataCSV, setCompareDataCSV] = useState<ICompareDataCSV[]>([]);

  ////////////////////////////////////// uSE EFFECTS ///////////////////////////////////
  useEffect(() => {
    const exportCompareDataCSVAPI = async () => {
      let thisCompareDataCSVAPI = await ExportCompareDataCSV(viewType, getLocationCodes());
      setCompareDataCSV(thisCompareDataCSVAPI);
    };
    //get/set locations
    exportCompareDataCSVAPI();

    //function
    function getLocationCodes() {
      let locCodes = '';
      if (locationCodes && locationCodes.length > 0) {
        locCodes = locationCodes.join(',');
      }
      return locCodes;
    }
  }, [viewType, locationCodes]);

  ///////////////////////////////////// FUNCTIONS ///////////////////////////////
  function exportCSV() {
    const csvData: any[] = [];
    compareDataCSV.forEach((item) => {
      let category = item.category;
      let measure = item.measure;
      let year = item.year;
      let dataType = item.dataType;
      let dataValueUnit = item.dataValueUnit;
      let location = item.location;
      let dataValue = item.dataValue;
      let lowConfidenceLimit = item.lowConfidenceLimit;
      let highConfidenceLimit = item.highConfidenceLimit;
      let populationCount = item.populationCount;
      csvData.push({
        Category: category,
        Measure: measure,
        Year: year,
        'Data Type': dataType,
        'Data Value Unit': dataValueUnit,
        Location: location,
        'Data Value': dataValue,
        'Low Confidence Limit': lowConfidenceLimit,
        'High Confidence Limit': highConfidenceLimit,
        'Population Count': populationCount,
      });
    });

    const csvConfig = mkConfig({
      fieldSeparator: ',',
      quoteCharacter: '"',
      decimalSeparator: '.',
      showColumnHeaders: true,
      showTitle: false,
      title: 'PLACES Comparison Report',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      filename: getExportFileName(),
    });
    // const csvExporter = new ExportToCsv(options);
    const csv = generateCsv(csvConfig)(csvData);

    //csvExporter.generateCsv([...csvData]);
    download(csvConfig)(csv);
  }
  function getExportFileName() {
    const dt = Moment().format('MMDDYYYY').toString();
    if (viewType === ConstantClass.County) {
      return `CountiesCompareData_${dt}`;
    } else {
      return `PlacesCompareData_${dt}`;
    }
  }
  //////////////////////////////////////////////////////////////////////
  return (
    <div
      onClick={() => {
        analytics.saveAsCSV(viewType, locationCodes);
        exportCSV();
      }}
      onKeyUp={(e) => keyUpWrapper(e, exportCSV)}
      role="button"
      tabIndex={0}
      className="link topic-csv"
    >
      <span className="x24 fill-p cdc-icon-excel me-1"></span>Save As CSV
    </div>
  );
};
