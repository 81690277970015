import React, { useEffect, useLayoutEffect, useState, Fragment } from 'react';
import * as analytics from './helper/analytics';
import { CDCHeader, CDCFooter } from '@cdcent/templatepackage-react';
import { NavLink } from './modules/NavLink';
import { SkipNav } from './modules/SkipNav';
import { BreadCrumbs } from './modules/BreadCrumbs';
import { Search } from './modules/Search';
import { LocationCountyModal } from './modules/LocationCountyModal';
import { LocationCityModal } from './modules/LocationCityModal';
import { SaveAsCSV } from './modules/SaveAsCSV';
import { SaveAsPDF } from './modules/SaveAsPDF';
import { CompareCategoryCounty } from './modules/CompareCategoryCounty';
import { CompareCategoryCity } from './modules/CompareCategoryCity';
import { FootNote } from './modules/FootNote';
import { FileFormat } from './modules/FileFormat';
import { IStatesAndCounty } from './models/StatesAndCounty';
import { IStatesAndCity } from './models/StatesAndCity';
import { getQueryParam, asArray, insertQueryParam } from './helper/common';
import { ConstantClass, TabIndexes } from './helper/ConstantClass';
import { PdfHeader } from './modules/PdfHeader';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { keyUpWrapper } from './helper/common';
import { Env } from './AppSettings';

function App() {
  const [firstLoad, setFirstLoad] = useState<boolean>(true);
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [searchTextCounty, setSearchTextCounty] = useState<string>('');
  const [searchCountCounty, setSearchCountCounty] = useState<number | undefined>(undefined);
  const [locationCounties, setLocationCounties] = useState<IStatesAndCounty[] | null>(null);
  const [editCountyCode, setEditCountyCode] = useState<string | null>(null);
  const [countyCodes, setCountyCodes] = useState<string[]>([]);
  const [openCountyModal, setOpenCountyModal] = useState<boolean>(false);
  const [viewType, setViewType] = useState<string>(''); //ConstantClass.County
  ////////////////////////////////////////////////////
  const [searchTextCity, setSearchTextCity] = useState<string>('');
  const [searchCountCity, setSearchCountCity] = useState<number | undefined>(undefined);
  const [cityCodes, setCityCodes] = useState<string[]>([]);
  const [editCityCode, setEditCityCode] = useState<string | null>(null);
  const [openCityModal, setOpenCityModal] = useState<boolean>(false);
  const [locationCities, setLocationCities] = useState<IStatesAndCity[] | null>(null);
  const viewTypePlace = 'place';

  //------------------------------ init ---------------------------------
  //1---------------------
  useLayoutEffect(() => {
    //check view
    let currrentView = getQueryParam('view');
    //check
    if (currrentView) {
      currrentView = currrentView.toLocaleLowerCase();
      if (currrentView === viewTypePlace) {
        currrentView = ConstantClass.City; //replace place with city
      }
    }
    if (currrentView === ConstantClass.City) {
      setViewType(currrentView);
      setTabIndex(TabIndexes.City);
    } else {
      currrentView = ConstantClass.County;
      setViewType(currrentView);
      setTabIndex(TabIndexes.County);
    }
    //set countyCodes/CityCodes from url
    const locationIds = asArray(getQueryParam('locationIds'));
    if (locationIds.length) {
      if (currrentView === ConstantClass.County) {
        setCountyCodes(locationIds);
      } else {
        setCityCodes(locationIds);
      }
    }

    //ANALYTICS
    //analytics.pageLoad();

    //Fire child components
    setFirstLoad(false);
  }, []);

  //2---------------------
  useEffect(() => {
    //this set user selected locations into URL
    if (viewType === ConstantClass.County) {
      insertQueryParam('view', ConstantClass.County);
      insertQueryParam('locationIds', countyCodes.join(','));
      analytics.changeLocation(viewType, countyCodes);
    }
    if (viewType === ConstantClass.City) {
      //insertQueryParam('view', ConstantClass.City);
      insertQueryParam('view', viewTypePlace); //replace city with place
      insertQueryParam('locationIds', cityCodes.join(','));
      analytics.changeLocation(viewType, cityCodes);
    }
  }, [viewType, countyCodes, cityCodes]);

  //------------------------------ FUNCTIONS ---------------------------------
  function TabSelected(tabIndex: number) {
    TabChanged(tabIndex);
  }
  function TabSelectedByKeyPressed(e: any, selectedIndex: number) {
    TabChanged(selectedIndex);
  }
  function TabChanged(selectedIndex: number) {
    if (selectedIndex === TabIndexes.County) {
      setViewType(ConstantClass.County);
      setTabIndex(selectedIndex);
    }
    if (selectedIndex === TabIndexes.City) {
      setViewType(ConstantClass.City);
      setTabIndex(selectedIndex);
    }
  }
  function getLocationCodes() {
    if (viewType === ConstantClass.County) {
      return countyCodes;
    }
    if (viewType === ConstantClass.City) {
      return cityCodes;
    }
    return [];
  }
  //------------------------------ Return --------------------------------
  return (
    <div>
      <div className="skip-nav">
        <SkipNav />
      </div>
      <div className="cdc-header">
        <Fragment>
          <CDCHeader title="PLACES: Local Data for Better Health" search={false} link={Env('places_home')} />
          {/* <NavLink /> */}
          <BreadCrumbs />
        </Fragment>
      </div>
      <div className="pdf-header">
        <PdfHeader />
      </div>
      <div className="container bg__white">
        <h3 className="mt-3">Compare Measures</h3>
        <Tabs onSelect={(index) => TabSelected(index)} selectedIndex={tabIndex}>
          <TabList>
            <Tab tabIndex="0" onKeyUp={(e) => keyUpWrapper(e, TabSelectedByKeyPressed, 0)}>
              <strong>County</strong>
            </Tab>
            <Tab tabIndex="0" onKeyUp={(e) => keyUpWrapper(e, TabSelectedByKeyPressed, 1)}>
              <strong>Place</strong>
            </Tab>
          </TabList>
          <TabPanel>
            <div id="tabCounty" className="tab-container">
              <main>
                <div className="search">
                  <Fragment>
                    <div className="flex-container m-0 border bg-light p-2">
                      <div id="divSearch" className="search-d1 mt-2">
                        <Search
                          {...{
                            viewType,
                            setSearchTextCounty,
                            setSearchCountCounty,
                            searchCountCounty,
                            setSearchTextCity,
                            setSearchCountCity,
                            searchCountCity,
                          }}
                        />
                      </div>
                      <div className="text-left search-d2 mt-2">
                        <LocationCountyModal
                          {...{
                            locationCounties,
                            countyCodes,
                            editCountyCode,
                            setEditCountyCode,
                            openCountyModal,
                            setLocationCounties,
                            setCountyCodes,
                            setOpenCountyModal,
                          }}
                        />
                      </div>
                      <div className="text-end search-d3 mt-2 ps-2">
                        <SaveAsPDF viewType={viewType} locationCodes={getLocationCodes()} />
                        <SaveAsCSV viewType={viewType} locationCodes={getLocationCodes()} />
                      </div>
                    </div>
                  </Fragment>
                </div>
                <div className="mt-2">
                  <CompareCategoryCounty
                    {...{
                      locationCounties,
                      countyCodes,
                      searchTextCounty,
                      setCountyCodes,
                      setEditCountyCode,
                      setOpenCountyModal,
                      setSearchCountCounty,
                      firstLoad,
                    }}
                  />
                </div>
                <div className="mt-4">
                  <FootNote viewType={viewType} locationCodes={getLocationCodes()} />
                </div>
                <div className="file-format mt-4 mb-4">
                  <FileFormat />
                </div>
              </main>
            </div>
          </TabPanel>
          <TabPanel>
            <div id="tabCity" className="tab-container">
              <main>
                <div className="search">
                  <Fragment>
                    <div className="flex-container m-0 border bg-light p-2">
                      <div id="divSearch" className="search-d1 mt-2">
                        <Search
                          {...{
                            viewType,
                            setSearchTextCounty,
                            setSearchCountCounty,
                            searchCountCounty,
                            setSearchTextCity,
                            setSearchCountCity,
                            searchCountCity,
                          }}
                        />
                      </div>
                      <div className="text-left search-d2 mt-2">
                        <LocationCityModal
                          {...{
                            locationCities,
                            cityCodes,
                            editCityCode,
                            setEditCityCode,
                            openCityModal,
                            setLocationCities,
                            setCityCodes,
                            setOpenCityModal,
                          }}
                        />
                      </div>
                      <div className="text-end search-d3 mt-2 ps-2">
                        <SaveAsPDF viewType={viewType} locationCodes={getLocationCodes()} />
                        <SaveAsCSV viewType={viewType} locationCodes={getLocationCodes()} />
                      </div>
                    </div>
                  </Fragment>
                </div>
                <div className="mt-2">
                  <CompareCategoryCity
                    {...{
                      locationCities,
                      cityCodes,
                      searchTextCity,
                      setCityCodes,
                      setEditCityCode,
                      setOpenCityModal,
                      setSearchCountCity,
                      firstLoad,
                    }}
                  />
                </div>
                <div className="mt-4">
                  <FootNote viewType={viewType} locationCodes={getLocationCodes()} />
                </div>
                <div className="file-format mt-4 mb-4">
                  <FileFormat />
                </div>
              </main>
            </div>
          </TabPanel>
        </Tabs>
      </div>
      <div className="cdc-footer">
        <CDCFooter />
      </div>
    </div>
  );
}

export default App;
