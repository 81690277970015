import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import '../node_modules/bootstrap/scss/bootstrap.scss';
import '@cdcent/templatepackage-react/assets/css/bootstrap.min.css';
import '@cdcent/templatepackage-react/assets/css/app.min.css';
import '@cdcent/templatepackage-react/assets/css/theme-indigo.min.css';
import './css/index.scss';
import './css/App.scss';
import './css/Tab.scss';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
