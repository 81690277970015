export const Env = (routeName: string) => {
  interface env {
    [key: string]: string;
  }

  const local: env = {
    cdc: 'https://wwwdev.cdc.gov',
    nccd: 'https://nccddev.cdc.gov',
    dataportal: 'https://chronicdata.cdc.gov/browse?category=500+Cities+%26+Places',
    api: 'http://localhost:8080/api',
    ind_definition_url: 'https://wwwdev.cdc.gov/places/measure-definitions/[CategoryName].html#[indicatorId]',
    file_format_url: 'https://wwwdev.cdc.gov/Other/plugins/',
    places_home: 'https://wwwdev.cdc.gov/places/',
    help_url: 'https://wwwdev.cdc.gov/places/tools/explore-places-compare-counties-report.html',
  };
  const dev: env = {
    cdc: 'https://wwwdev.cdc.gov',
    nccd: 'https://nccddev.cdc.gov',
    dataportal: 'https://chronicdata.cdc.gov/browse?category=500+Cities+%26+Places',
    api: '/api',
    ind_definition_url: 'https://wwwdev.cdc.gov/places/measure-definitions/[CategoryName].html#[indicatorId]',
    file_format_url: 'https://wwwdev.cdc.gov/Other/plugins/',
    places_home: 'https://wwwdev.cdc.gov/places/',
    help_url: 'https://wwwdev.cdc.gov/places/tools/explore-places-compare-counties-report.html',
  };
  const qa: env = {
    cdc: dev.cdc,
    nccd: 'https://nccdqa.cdc.gov',
    dataportal: 'https://chronicdata.cdc.gov/browse?category=500+Cities+%26+Places',
    api: '/api',
    ind_definition_url: 'https://wwwdev.cdc.gov/places/measure-definitions/[CategoryName].html#[indicatorId]',
    file_format_url: 'https://wwwdev.cdc.gov/Other/plugins/',
    places_home: 'https://wwwdev.cdc.gov/places/',
    help_url: 'https://wwwdev.cdc.gov/places/tools/explore-places-compare-counties-report.html',
  };
  const int: env = {
    cdc: 'https://www.cdc.gov',
    nccd: 'https://nccd.cdc.gov',
    dataportal: 'https://chronicdata.cdc.gov/browse?category=500+Cities+%26+Places',
    api: '/api',
    ind_definition_url: 'https://www.cdc.gov/places/measure-definitions/[CategoryName].html#[indicatorId]',
    file_format_url: 'https://www.cdc.gov/Other/plugins/',
    places_home: 'https://www.cdc.gov/places/',
    help_url: 'https://www.cdc.gov/places/tools/explore-places-compare-counties-report.html',
  };
  const prod: env = {
    cdc: 'https://www.cdc.gov',
    nccd: 'https://nccd.cdc.gov',
    dataportal: 'https://chronicdata.cdc.gov/browse?category=500+Cities+%26+Places',
    api: '/api',
    ind_definition_url: 'https://www.cdc.gov/places/measure-definitions/[CategoryName].html#[indicatorId]',
    file_format_url: 'https://www.cdc.gov/Other/plugins/',
    places_home: 'https://www.cdc.gov/places/',
    help_url: 'https://www.cdc.gov/places/tools/explore-places-compare-counties-report.html',
  };
  ////////////////////////////////////////////////////////////////////////
  if (window.location.href.match(/places-qa.apps.ecpaas-dev.cdc.gov/)) {
    return qa[routeName];
  } else if (window.location.href.match(/places-dev.apps.ecpaas-dev.cdc.gov/)) {
    return dev[routeName];
  } else if (window.location.href.match(/places-int.cdc.gov/)) {
    return int[routeName];
  } else if (window.location.href.match(/localhost/)) {
    return local[routeName];
  } else {
    return prod[routeName];
  }
};
