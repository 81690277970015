import React from 'react';

export const PdfHeader = () => {
  return (
    <div className="container p-0">
      <div className="row m-0 border bg-light ps-0 pt-2 pb-2">
        <div className="col-9 ps-0 ms-0 pdf-title">PLACES: Local Data for Better Health Compare Counties Report</div>
        <div className="col-3 text-end">
          <img src="/cdc-logo.png" className="pdf-title-logo" alt="logo" />
        </div>
      </div>
    </div>
  );
};
