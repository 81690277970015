import React from 'react';
import { Env } from '../AppSettings';

const crumbs = [
  {
    label: 'CDC',
    route: Env('cdc'),
  },
  {
    label: 'Division of Population Health ',
    route: Env('cdc') + '/nccdphp/divisions-offices/about-the-division-of-population-health.html',
  },
  {
    label: 'PLACES Home',
    route: Env('cdc') + '/places/',
  },
];

export const BreadCrumbs = () => {
  return (
    <div className="container">
      <div className="row mt-2 pb-2">
        <div className="col-8">
          <nav role="navigation" aria-label="Breadcrumb" className="d-none d-lg-block">
            <ol className="breadcrumb">
              {crumbs.map((crumb) => (
                <li key={crumb.label} className="breadcrumb-item">
                  <a href={crumb.route}>{crumb.label}</a>
                </li>
              ))}
            </ol>
          </nav>
        </div>
        <div className="col-4 mt-2">
          <nav className="text-end">
            <a href={Env('dataportal')} target="_blank" rel="noopener noreferrer">
              Data Portal
            </a>{' '}
            |{' '}
            <a href={Env('help_url')} target="_blank" rel="noopener noreferrer">
              Help
            </a>
          </nav>
        </div>
      </div>
    </div>
  );
};
